$(document).on('click', '#modalModifyInvoiceItem .js__save_products', function () {
    showErrorMessages()
    if (invalidQuantity() || invalidTitle() || invalidPrice()) {
        return false
    }
    let $invoiceItemList = $('.invoice-items')
    $invoiceItemList.empty()

    function addHiddenField(value, name, index) {
        if(value !== undefined && value !== 'undefined') {
            let hiddenField = `<input type="hidden" value="${value}" name="invoice[invoice_items_attributes][${index}][${name}]">`
            $invoiceItemList.find(`.row-${index}`).append(hiddenField)
        }
    }

    function addRow(title, price, quantity, btw, totalLine, index, destroy) {
        let newRow
        if(destroy === 1) {
            newRow = `<div class="row-${index}"></div>`
        } else {
            newRow = `<div class="row form-group row-${index}">
                          <div class="col-12 d-flex">
                              <div class="col-title">${title}</div>
                              <div class="col-right">${price}</div>
                              <div class="col-right"><label>${quantity}</label></div>
                              <div class="col-right">${btw*100}%</div>
                              <div class="col-right">${totalLine}</div>
                          </div>
                      </div>`
        }

        $invoiceItemList.append(newRow)
    }

    let subTotal = 0
    let btwHash = {}
    $('.js__product_row').each(function (index, element) {
        function calculateAmount(price, quantity, btw) {
            if(btwHash[btw] === undefined) {
                btwHash[btw] = 0
            }
            subTotal += price * quantity
            btwHash[btw] += price * quantity * btw
        }

        let title
        let price
        let quantity
        let btw
        let totalLine
        let priceText
        let id = $(this).data('id')
        let destroy = $(this).data('destroy')

        if ($(this).hasClass('external-item')) {
            title = $(this).find('.js__title').val()
            price = $(this).find('.js__price').val()
            quantity = $(this).find('.js__quantity').val()
            btw = parseFloat($(this).find('.js__btw').val())
            totalLine = price * quantity * (1+btw)

            calculateAmount(price, quantity, btw);

            priceText = Number(price).toLocaleString("nl-NL", {style: "currency",currency: "EUR"})
            totalLine = Number(totalLine).toLocaleString("nl-NL", {style: "currency",currency: "EUR"})
            addRow(title, priceText, quantity, btw, totalLine, index, destroy);
        } else {
            title = $(this).data('title')
            let productId = $(this).data('product-id')
            price = $(this).data('price')
            let productPreferenceId = $(this).data('product-preference-id')
            btw = parseFloat($(this).data('btw'))
            quantity = 1
            if (productPreferenceId === 'undefined' || productPreferenceId === null) {
                quantity = $(this).find('input.js__quantity').val()
            }
            totalLine = price * quantity * (1+btw)

            calculateAmount(price, quantity, btw);

            priceText = Number(price).toLocaleString("nl-NL", {style: "currency",currency: "EUR"})
            totalLine = Number(totalLine).toLocaleString("nl-NL", {style: "currency",currency: "EUR"})

            addRow(title, priceText, quantity, btw, totalLine, index, destroy);
            addHiddenField(productId, 'product_id', index);
            addHiddenField(productPreferenceId, 'product_preference_id', index);
        }

        addHiddenField(id, 'id', index);
        addHiddenField(destroy, '_destroy', index);
        addHiddenField(title, 'title', index);
        addHiddenField(price, 'price', index);
        addHiddenField(quantity, 'quantity', index);
        addHiddenField(btw, 'btw', index);
    })
    let sumBtw = Object.values(btwHash).reduce((a, b) => a + b, 0)
    let totalAmount = Number(subTotal + sumBtw).toLocaleString("nl-NL", {style: "currency",currency: "EUR"})
    subTotal = Number(subTotal).toLocaleString("nl-NL", {style: "currency",currency: "EUR"})
    let invoiceSummaryHtml = ''

    let btwHashKeys = Object.keys(btwHash).sort()
    btwHashKeys.forEach(function (i){
        invoiceSummaryHtml +=
            `<div class="row form-group">
                <div class="col-12 d-flex">
                    <div class="col-title"></div>
                    <div class="col-right"></div>
                    <div class="col-right"></div>
                    <div class="col-right"><strong>Tax (${i * 100}%)</strong></div>
                    <div class="col-right">
                        ${Number(btwHash[i]).toLocaleString("nl-NL", {style: "currency",currency: "EUR"})}
                    </div>
                </div>
            </div>`
    })

    let invoiceSummary = `<hr/>
        <div class="row form-group">
            <div class="col-12 d-flex">
                <div class="col-title"></div>
                <div class="col-right"></div>
                <div class="col-right"></div>
                <div class="col-right"><strong>Subtotal</strong></div>
                <div class="col-right">${subTotal}</div>
            </div>
        </div>
        ${invoiceSummaryHtml}
        <div class="row form-group">
            <div class="col-12 d-flex">
                <div class="col-title"></div>
                <div class="col-right"></div>
                <div class="col-right"></div>
                <div class="col-right"><strong>Total</strong></div>
                <div class="col-right">${totalAmount}</div>
            </div>
        </div>`

    $('#invoice-form .total-invoice').empty()
    $('#invoice-form .total-invoice').append(invoiceSummary)

    $(this).closest('#modalModifyInvoiceItem').modal('hide')
    $(".modal-backdrop").hide();
})
