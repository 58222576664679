$(document).on('turbolinks:load', function () {
  $('.js_checkbox-select-all').on('click', function() {
    $('.js__checkbox-select').prop('checked', $(this).prop('checked'))
  })

  $('.js__checkbox-select').on('click', function() {
    if (!$(this).prop('checked')) {
      $('.js_checkbox-select-all').prop('checked', false)
    }

    if ($('.js__checkbox-select:checked').length == $('.js__checkbox-select').length ){
      $('.js_checkbox-select-all').prop('checked', true)
    }
  })
})

$(document).on('click', '.js__checkbox-select', function (){
  if($("input.js__checkbox-select:checked").length > 0) {
    $('.js__toggle_checkbox_button').removeClass('disabled')
  } else {
    $('.js__toggle_checkbox_button').addClass('disabled')
  }
});
