window.focusToInput = function (inputClassName) {
    $('.nested-fields:visible')
        .find(`input.${inputClassName}`)
        .filter(function() { return $(this).val() == ""; })
        .focus().val('');
}

window.interactInput = function(e, identifierType, rgPattern) {
    let allInputs = $('.nested-fields:visible');
    let listInput = $.map(allInputs, (item) => {
        return $(item).find(`input.product-${identifierType}`).val()
    });
    let listInputValidator = listInput.map(item => {
        return rgPattern.test(item)
    });

    let isAllInputValid = $.inArray(false, listInputValidator) == -1

    function addNewRow(inputClassName) {
        $('.add_fields')[0].click();
        let $nextImei = $(e.target).closest('.nested-fields').next().find(`.imei-group input.${inputClassName}`);
        if ($nextImei.val() == '') {
            $nextImei.focus().val('');
        } else {
            focusToInput(inputClassName)
        }
    }

    if (isAllInputValid) {
        let $identifierSelectBox = $('#product_identifier')
        let identifierList = $identifierSelectBox.data('identifier_list')
        let identifierSelected = $identifierSelectBox.val()

        if (identifierSelected == identifierList['identify_by_emei_serial']) {
            if($(e.target).hasClass('product-imei')) {
                let $serialInput = $(e.target).closest('.nested-fields').find('.serial-group input.product-serial')
                let serialValue = $serialInput.val();
                $serialInput.focus().val(serialValue);
            } else if ($(e.target).hasClass('product-serial')) {
                addNewRow('product-imei');
            }
        } else {
            addNewRow(`product-${identifierType}`);
        }
    } else {
        focusToInput(`product-${identifierType}`)
    }
}

window.validateIdentifier = function(e, regexPattern) {
    let inputValue = $(e.target).val();
    let className = $(e.target).attr("class");
    let allInputs;
    if(className.includes('product-imei')) {
        allInputs = $('.nested-fields:visible input.product-imei');
    } else {
        allInputs = $('.nested-fields:visible input.product-serial');
    }
    let allInputValues = $.map(allInputs, (item) => {
        return $(item).val()
    });

    let uniqueInputValues = Array.from(new Set(allInputValues))
    let isUnique = uniqueInputValues.length === allInputValues.length
    let validPattern = regexPattern.test(inputValue)

    if(validPattern && isUnique ){
        $(e.target).removeClass('invalid-pattern');
        $(e.target).parent().find('.warning-invalid-pattern').addClass('d-none')
    } else {
        $(e.target).addClass('invalid-pattern');
        $(e.target).parent().find('.warning-invalid-pattern').removeClass('d-none')
        if(!validPattern) {
            $(e.target).parent().find('.warning-invalid-pattern em.text-danger').text('Invalid Input.')
        } else if(!isUnique) {
            $(e.target).parent().find('.warning-invalid-pattern em.text-danger').text('Input duplicated.')
        }
        return false
    }
    return true
}
