$(document).on('input', '.js__customer_postal_code, .js__customer_house_number', function () {
  const url = $(this).data('url')
  const postal_code = $('input.js__customer_postal_code').val()
  const house_number = $('input.js__customer_house_number').val()
  if (postal_code && house_number) {
    $.ajax({
      url,
      method: 'get',
      data: {
        postal_code,
        house_number,
        authenticity_token: $('[name="csrf-token"]')[0].content
      },
      success: function (res) {
        const data = res.data
        const $error = $('.js__customer_postal_code').siblings('span.text-danger')
        if (res.success) {
          $('input.js__customer_address').val(data.streetName)
          $('input.js__customer_city').val(data.city)
          $error.text('')
        } else {
          $('input.js__customer_address').val('')
          $('input.js__customer_city').val('')
          $error.text(res.message)
        }
      },
    })
  }
})
