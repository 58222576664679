$(document).on('input', '.js__autocomplete_product_title', function () {
    let title = $(this).val();
    let $tableProductList = $('.product-list')

    $(this).autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "/products/xhr_search",
                method: 'get',
                data: {
                    title: title,
                    authenticity_token: $('[name="csrf-token"]')[0].content
                },
                success: function (data) {
                    let result = $.map(data.products, function (product) {
                        return {
                            label: product['title'],
                            value: product['title'],
                            title: product['title'],
                            product_id: product['id'],
                            price: product['price'],
                            product_preference_id: product['product_preference_id'],
                            identifier: product['identifier'],
                            imei: product['imei'],
                            serial: product['serial'],
                            stock: product['stock'],
                            price_text: product['price_text'],
                            btw: product['btw']
                        };
                    })
                    response(result)
                }
            });
        },
        select: function (event, product) {
            let newRowProduct = modalBuildItemRow(product['item'])
            $tableProductList.append(newRowProduct)
        }
    }).autocomplete("instance")._renderItem = function (ul, item) {
        let identifierText = ''
        if (item.identifier === 'identify_by_imei') {
            identifierText = `IMEI: ${item.imei}`
        } else if (item.identifier === 'identify_by_serial') {
            identifierText = `Serial: ${item.serial}`
        } else if (item.identifier === 'identify_by_emei_serial') {
            identifierText = `IMEI: ${item.imei} - Serial: ${item.serial}`
        }

        return $("<li class='each'>")
            .append("<div class='item'><span class='title'>" +
                `${item.title} (${item.stock})` + "</span><br><span class='price'>" +
                `&euro; ${item.price}` + "</span><br><span class='identifier'>" + identifierText + "</span></div>")
            .appendTo(ul);
    };
})

$(document).on('click', '.js__remove_item', function () {
    let $row = $(this).closest('.js__product_row')
    $row.hide()
    $row.attr('data-destroy', 1)
})
