window.modalBuildItemRow = function (item) {
    let id = item['id']
    let price = item['price']
    let price_text = item['price_text']
    let product_id = item['product_id']
    let productPreferenceID = item['product_preference_id']
    let title = item['title']
    let stock = item['stock']
    let btw = item['btw']
    let productInfo = `<div>${title}</div>`

    let quantityElement = `<input type="number" data-max='${stock}' step="1" value="" class="form-control js__quantity text-right"/>`
    if(item['quantity'] !== undefined) {
        quantityElement = `<input type="number" data-max='${stock}' step="1" value="${item['quantity']}" class="form-control js__quantity text-right"/>`
    }

    if (item['identifier'] === 'identify_by_imei') {
        productInfo += `<span>EMEI: ${item['imei']}</span>`
        quantityElement = '<lable>1</lable>'
    } else if (item['identifier'] === 'identify_by_serial') {
        productInfo += `<span>Serial: ${item['serial']}</span>`
        quantityElement = '<lable>1</lable>'
    } else if (item['identifier'] === 'identify_by_emei_serial') {
        productInfo += `<span>EMEI: ${item['imei']}</span>`
        quantityElement = '<lable>1</lable>'
    }

    let btwElement = `<lable>${btw * 100}%</lable>`
    return `<div class="form-group row js__product_row" data-id='${id}'
                data-title='${title}' data-product-id='${product_id}' 
                data-product-preference-id=${productPreferenceID} data-price='${price}' 
                data-price-text='${price_text}' data-stock='${stock}' data-btw='${btw}'>
                <div class="col-12 d-flex">
                    <div class="col-title">${productInfo}</div>
                    <div class="col-price">${price_text}</div>
                    <div class="col-quantity">${quantityElement}</div>
                    <div class="col-btw">${btwElement}</div>
                    <div class="col-trash js__remove_item"><i class="cil-trash text-danger" role="button"></i></div>
                </div>
            </div>`
}


window.showErrorMessages = function() {
    $('input.js__quantity').each(function (index, element) {
        let max = parseInt($(this).data('max'))
        let value = parseInt($(this).val())

        $(this).parent().find('.warning-message').remove()
        let text = ''
        if ($(this).val() === '') {
            text = 'quantity must be present'
        } else if (value < 1) {
            text = 'larger than 0'
        } else if (value > max) {
            text = `only ${max} in stock`
        }
        if (text !== '') {
            $(`<span class="text-danger warning-message"><small><i>${text}</i></small></span>`).insertAfter($(this))
        }
    })

    $('input.js__title').each(function (index, element) {
        $(this).parent().find('.warning-message').remove()
        let text = ''
        if ($(this).val().length < 3) {
            text = 'title must more than 3 characters'
            $(`<span class="text-danger warning-message"><small><i>${text}</i></small></span>`).insertAfter($(this))
        }
    })

    $('input.js__price').each(function (index, element) {
        $(this).parent().find('.warning-message').remove()
        let text = ''
        if ($(this).val() === '' || parseInt($(this).val()) < 0) {
            text = 'invalid price'
            $(`<span class="text-danger warning-message"><small><i>${text}</i></small></span>`).insertAfter($(this))
        }
    })
}

window.invalidQuantity = function() {
    let invalid = false
    $('.js__quantity').each(function () {
        if($(this).closest('.js__product_row').data('destroy') === 1) {
            return false;
        }

        let value = parseInt($(this).val())
        let min = 1
        let max = parseInt($(this).data('max'))
        if ($(this).val() === '' || value < min) {
            invalid = true
            return false
        } else if ($(this).data('max') !== undefined && value > max) {
            let titleProduct = $(this).closest('.col-12').find('.col-title').text()
            let r = confirm(`There is only ${max} items ${titleProduct} in stock. Would you like to allow a backorder of ${value-max} pcs?`)
            if (r !== true) {
                invalid = true
                return false
            }
        }
    })
    return invalid
}

window.invalidTitle = function() {
    let invalid = false
    $('.js__title').each(function (index, element) {
        if($(this).closest('.js__product_row').data('destroy') === 1) {
            return false; // breaks
        }

        if ($(this).val().length < 3) {
            invalid = true
            return false
        }
    })
    return invalid
}

window.invalidPrice = function() {
    let invalid = false
    $('.js__price').each(function (index, element) {
        if($(this).closest('.js__product_row').data('destroy') === 1) {
            return false; // breaks
        }

        if ($(this).val() === '' || parseInt($(this).val()) < 1) {
            invalid = true
            return false
        }
    })
    return invalid
}
