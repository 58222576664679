$(document).on('click', '#order-form input[type=submit]', function(e) {
  if ($('.js__quantity').length > 0 && invalidQuantity()) {
    showErrorMessages()
    e.preventDefault()
  }
})

$(document).on('input', '.js__autocomplete_order_product', function () {
  let title = $(this).val();
  let $tableProductList = $('.product-list')

  $(this).autocomplete({
    source: function (request, response) {
        $.ajax({
            url: "/products/xhr_search",
            method: 'get',
            data: {
                title: title,
                authenticity_token: $('[name="csrf-token"]')[0].content
            },
            success: function (data) {
                let result = $.map(data.products, function (product) {
                    return {
                        label: product['title'],
                        value: product['title'],
                        title: product['title'],
                        product_id: product['id'],
                        price: product['price'],
                        product_preference_id: product['product_preference_id'],
                        identifier: product['identifier'],
                        imei: product['imei'],
                        serial: product['serial'],
                        stock: product['stock'],
                        price_text: product['price_text'],
                        btw: product['btw']
                    };
                })
                response(result)
            }
        });
    },
    select: function (event, product) {
        let newRowProduct = buildItem(product['item'])
        $tableProductList.append(newRowProduct)
    }
  }).autocomplete("instance")._renderItem = function (ul, item) {
      let identifierText = ''
      if (item.identifier === 'identify_by_imei') {
          identifierText = `IMEI: ${item.imei}`
      } else if (item.identifier === 'identify_by_serial') {
          identifierText = `Serial: ${item.serial}`
      } else if (item.identifier === 'identify_by_emei_serial') {
          identifierText = `IMEI: ${item.imei} - Serial: ${item.serial}`
      }

      return $("<li class='each'>")
          .append("<div class='item'><span class='title'>" +
              `${item.title} (${item.stock})` + "</span><br><span class='price'>" +
              `&euro; ${item.price}` + "</span><br><span class='identifier'>" + identifierText + "</span></div>")
          .appendTo(ul);
  };

  function buildItem(item) {
    let index = $('.js__product_row').length
    let id = item['id']
    let price_text = item['price_text']
    let product_id = item['product_id']
    let productPreferenceID = item['product_preference_id']
    let stock = item['stock']
    let btw = item['btw']
    let productInfo = `<div>${item['title']}</div>`

    if (item['identifier'] === 'identify_by_imei') {
        productInfo += `<span>EMEI: ${item['imei']}</span>`
        quantityElement = '<lable>1</lable>'
    } else if (item['identifier'] === 'identify_by_serial') {
        productInfo += `<span>Serial: ${item['serial']}</span>`
        quantityElement = '<lable>1</lable>'
    } else if (item['identifier'] === 'identify_by_emei_serial') {
        productInfo += `<span>EMEI: ${item['imei']}</span>`
        quantityElement = '<lable>1</lable>'
    }

    let btwElement = `<lable>${btw * 100}%</lable>`
    return `<div class="form-group row js__product_row">
                ${generateHiddenField(id, 'id', index)}
                ${generateHiddenField(product_id, 'product_id', index)}
                ${generateHiddenField(productPreferenceID, 'product_preference_id', index)}
                ${generateHiddenField(0, '_destroy', index, 'order_items_destroy')}
                <div class="col-12 d-flex">
                    <div class="col-title">${productInfo}</div>
                    <div class="col-price">${price_text}</div>
                    <div class="col-quantity">
                        <input type="number" data-max='${stock}' data-min="1" step="1" name="order[order_items_attributes][${index}][quantity]" class="form-control js__quantity text-right"/>
                    </div>
                    <div class="col-btw">${btwElement}</div>
                    <div class="col-trash js__order_remove_item"><i class="cil-trash text-danger" role="button"></i></div>
                </div>
            </div>`
  }

  function generateHiddenField(value, name, index, className = '') {
    if (value !== undefined && value !== 'undefined') {
        return `<input type="hidden" value="${value}" name="order[order_items_attributes][${index}][${name}]" class="${className}">`
    }
    return ''
  }
})

$(document).on('click', '.js__order_remove_item', function () {
  let $row = $(this).closest('.js__product_row')
  $row.hide()
  $row.find('.order_items_destroy').val(1)
})
