$(document).on('turbolinks:load', function () {
    if ($('.js__change-workflow').val() === '') {
        $('.js__toggle-invoice-rule').hide()
    }
})

$(document).on('change', '.js__change-workflow', function () {
    let id = $(this).val();
    if (id === '' || id === undefined) {
        $('.js__toggle-invoice-rule').hide()
        return
    }
    let workflowPath = $(this).data('urlworkflowpath').replace(':id', id);

    $.ajax({
        url: workflowPath,
        method: 'get',
        format: 'json',
        data: {
            authenticity_token: $('[name="csrf-token"]')[0].content
        },
        success: function (response){
            $('.js__checkbox-text-reminder-activated').html(response['reminder_activated_message'])
            $('.js__checkbox-warn-expired').html(response['warn_when_expired_message'])
            $('.js__toggle-invoice-rule').show()
        }
    })
});
