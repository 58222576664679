window.toggleProductIdentifierSelectBox = function () {
    let $identifierSelectBox = $('#product_identifier')
    if ($identifierSelectBox.length === 0) return
    let identifierList = $identifierSelectBox.data('identifier_list')
    let selectedIdentifier =  Object.keys(identifierList).find(key => identifierList[key] == $identifierSelectBox.val());

    if(selectedIdentifier === 'identify_by_imei' ){
        $('#identifies').show()
        $('.imei-group').show()
        $('.serial-group').hide()
    } else if(selectedIdentifier === 'identify_by_serial' ){
        $('#identifies').show();
        $('.serial-group').show()
        $('.imei-group').hide()
    } else if(selectedIdentifier === 'identify_by_emei_serial' ) {
        $('#identifies').show();
        $('.serial-group').show()
        $('.imei-group').show()
    } else {
        $('#identifies').hide();
    }
}

window.countNestedFieldRow = function () {
    let $identifierSelectBox = $('#product_identifier')
    if ($identifierSelectBox.length === 0) return
    let identifierList = $identifierSelectBox.data('identifier_list')
    let selectedIdentifier =  Object.keys(identifierList).find(key => identifierList[key] == $identifierSelectBox.val());

    if(selectedIdentifier === 'identify_by_imei' ){
        $('#identifies').show()
        $('.imei-group').show()
        $('.serial-group').hide()
        dynamicProductStockField()
    } else if(selectedIdentifier === 'identify_by_serial' ){
        $('#identifies').show();
        $('.serial-group').show()
        $('.imei-group').hide()
        dynamicProductStockField()
    } else if(selectedIdentifier === 'identify_by_emei_serial' ) {
        $('#identifies').show();
        $('.serial-group').show()
        $('.imei-group').show()
        dynamicProductStockField()
    } else {
        $('#identifies').hide();
        $('#product_stock').attr('disabled', false)
    }

    function dynamicProductStockField() {
        let countNestedFieldRow = $('#identifies .nested-fields').length
        let countHideNestedRow = $('#identifies .nested-fields[style="display: none;"]').length
        let $productStock = $('#product_stock')
        $productStock.attr('disabled', true)
        $productStock.val(countNestedFieldRow - countHideNestedRow)
    }
}
