window.addNewImei = function(e) {
    let $identifierSelectBox = $('#product_identifier')
    let identifierList = $identifierSelectBox.data('identifier_list')
    let identifierSelected = $identifierSelectBox.val()

    if (identifierSelected != identifierList['identify_by_imei'] &&
        identifierSelected != identifierList['identify_by_emei_serial']) {
        return
    }
    if (e.which == 13 || e.keydown == 13) {
        let rgPattern = /[0-9]{15}/
        let identifierType = 'imei'

        if(validateIdentifier(e, rgPattern)) {
            interactInput(e, identifierType, rgPattern)
        }
        return false;
    }
    return true;
}

window.typingImei = function (e) {
    let regexPattern = /[0-9]{15}/
    validateIdentifier(e, regexPattern)
}
