class changeIdentifier {
    constructor() {
        this.identifierSelection = $('#product_identifier');
        this.iterateIdentifierSelection();
    }

    iterateIdentifierSelection() {
        if (this.identifierSelection.length === 0) return

        this.identifierSelection[0].addEventListener('change', element => {
            window.toggleProductIdentifierSelectBox();
            window.countNestedFieldRow()
        })
    }
}
window.addEventListener('turbolinks:load', () => new changeIdentifier())
window.addEventListener('turbolinks:load', () => window.toggleProductIdentifierSelectBox())
window.addEventListener('turbolinks:load', () => window.countNestedFieldRow())
