$(document).on('click', '.js__add-external-item', function () {
    let btwList = $(this).closest('#modalModifyInvoiceItem').data('btw-list')
    let btwOptions = ''
    $.each(btwList, function (index, value) {
        btwOptions += `<option value="${value}">${value * 100}%</option>`
    })
    let newRowProduct =
        `<div class="form-group row js__product_row external-item">
            <div class="col-12 d-flex">
                <div class="col-title"><input type="text" class="form-control js__title"/></div>
                <div class="col-price"><input type="number" min="0.01" step="0.01" class="form-control js__price"/></div>
                <div class="col-quantity"><input type="number" min="1" step="1" class="form-control js__quantity"/></div>
                <div class="col-btw">
                    <select name="btw" class="form-control js__btw">
                      ${btwOptions}
                    </select>
                </div>
                <div class="col-actions js__remove_item"><i class="cil-trash text-danger" role="button"></i></div>
            </div>
        </div>`

    let $modal = $(this).closest('.modal')
    let $tableProductList = $modal.find('.product-list')

    $tableProductList.append(newRowProduct)
})
