$(document).on('input', '#product_search .js__autocomplete_product_imei_serial', function() {
  let imei_serial = $(this).val()
  let url = $(this).data('url-search')

  $(this).autocomplete({
    minLength: 3,
    source: function (request, response) {
      $.ajax({
        url,
        method: 'get',
        data: {
          imei_serial,
          authenticity_token: $('[name="csrf-token"]')[0].content
        },
        success: function (res) {
          let result = $.map(res.data, function (item) {
            return { ...item, label: item.code, value: item.code }
          })
          response(result)
        }
      })
    }
  }).autocomplete("instance")._renderItem = function (ul, item) {
    let item_html = "<div class='d-flex flex-column'>" +
                      "<span class='font-weight-bold'>" + item.code + "</span>" +
                      "<span class='font-italic'>" + item.type + "</span>" +
                    "</div>"
    return $("<li class='each'>").append(item_html).appendTo(ul)
  }
})
