$(document).on('focusout', '.js__modify-quantity', function (e) {
    let quantity = e.target.textContent
    let url = $(this).data('url-update')
    let $self = $(this)
    $.ajax({
        url: url,
        method: 'put',
        data: {
            invoice_items: {
                quantity: quantity,
            },
            authenticity_token: $('[name="csrf-token"]')[0].content
        },
        success: function (response) {
            let amountDue = response['amount_due']
            $('.js__amount-due').html(`<strong>${amountDue}</strong>`)
            let messages = `<div class="alert alert-success">${response['message']}</div>`
            $('.toast .toast-body').html(messages)
            $('.toast').toast('show')
        },
        error: function (xhr, status, error) {
            let ul = '<div class="alert alert-danger"><ul>'
            $.each(xhr.responseJSON['error_messages'], function (index, value) {
                ul += `<li>${value}</li>`
            })
            ul += '</ul></div>'
            $('.toast .toast-body').html(ul)
            $('.toast').toast('show')
            $self.focus()
        }
    })
})
