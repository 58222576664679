// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require ('jquery')
global.$ = require('jquery')
require ('moment/dist/moment')
require('@coreui/coreui/dist/js/coreui.bundle.min')
require('@fortawesome/fontawesome-free/js/all.min.js')
require('popper.js/dist/popper.min')
require('bootstrap/dist/js/bootstrap.min')
require('bootstrap-select/dist/js/bootstrap-select')
require('jquery-ui/ui/widgets/autocomplete.js')
require('bootstrap-daterangepicker/daterangepicker')
require('ion-rangeslider/js/ion.rangeSlider.min')

require('./daterangepicker')

require('./products/autocomplete-imei-serial')

require('./product-form/toggleIdentifier')
require('./product-form/changeIdentifier')

require('./product-preference-fields/general')
require('./product-preference-fields/imei')
require('./product-preference-fields/serials')

require('./nested-forms/addFields')
require('./nested-forms/removeFields')

require('./bootstrap-select')

require('./invoice-forms/modal-select-items/general')
require('./invoice-forms/modal-select-items/autocomplete-search-products')
require('./invoice-forms/modal-select-items/add-external-product')
require('./invoice-forms/modal-select-items/write-down-items')

require('./invoice-forms/modify-customer')
require('./invoice-forms/modify-quantity')
require('./invoice-forms/modify-item-list')
require('./invoice-forms/duplicate-item-list')
require('./invoice-forms/change-workflow')

require('./customers/postal-code-autofill')

require('./order-forms/product-autocomplete')
require('./select-all')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
