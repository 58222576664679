$(document).on('click', '#invoice-form .js__modifyInvoiceItemList', function (){
  let url = $(this).data('url')
  $.ajax({
    url,
    method: 'get',
    format: 'json',
    data: {
      authenticity_token: $('[name="csrf-token"]')[0].content
    },
    success: function (response){
      let $productList = $('#modalModifyInvoiceItem .product-list')

      if($productList.find('.js__product_row').length === 0) {
        let invoiceItems = response['invoice']['items']
        $.each(invoiceItems, function (index, item) {
          if (item.id == null) {
            item.id = ''
          }
          let newRowProduct = modalBuildItemRow(item)
          $productList.append(newRowProduct)
        })
      }
    }
  })
})
