$(document).on('click', '#edit-invoice .js__modifyInvoiceItemList', function (){
    let modifyUrl = $(this).data('url-modify');
    $.ajax({
        url: modifyUrl,
        method: 'get',
        format: 'json',
        data: {
            authenticity_token: $('[name="csrf-token"]')[0].content
        },
        success: function (response){
            let $productList = $('#modalModifyInvoiceItem .product-list')

            if($productList.find('.js__product_row').length === 0) {
                let invoiceItems = response['invoice']['items']
                $.each(invoiceItems, function (index, item) {
                    let newRowProduct = modalBuildItemRow(item)
                    $productList.append(newRowProduct)
                })
            }
        }
    })
})
