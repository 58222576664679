window.enterNewSerial = function(e) {
    let $identifierSelectBox = $('#product_identifier')
    let identifierList = $identifierSelectBox.data('identifier_list')
    let identifierSelected = $identifierSelectBox.val()
    if (identifierSelected != identifierList['identify_by_serial'] &&
        identifierSelected != identifierList['identify_by_emei_serial']) {
        return
    }

    if (e.which == 13 || e.keydown == 13) {
        let identifierType = 'serial'
        let rgPattern = /^[a-zA-Z0-9_.-]{3,}$/

        if(validateIdentifier(e, rgPattern)) {
            interactInput(e, identifierType, rgPattern)
        }
        return false
    }
}

window.typingSerial = function (e) {
    let regexPattern = /^[a-zA-Z0-9_.-]*$/
    validateIdentifier(e, regexPattern)
}
